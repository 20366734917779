.app {
  min-height: 100vh;
  background-color: #1a1a2e;
  color: white;
  padding: 2rem;
  font-family: 'Segoe UI', 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #e94560;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

p {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #e94560;
  letter-spacing: 0.2em;
}

form {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

input {
  padding: 0.8rem 1.2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  background-color: #16213e;
  color: white;
  width: 250px;
}

input:focus {
  outline: 2px solid #e94560;
}

button {
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  background-color: #e94560;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #ff5472;
}

.loading {
  font-size: 1.5rem;
  color: #e94560;
  margin-top: 2rem;
}

.score {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;
  transition: color 0.3s;
}


.display-table {
  table-layout: fixed;
  width: 40%;
  margin: 0 auto;
  border-collapse: collapse;
}

.display-table td {
  padding: 10px;
  border: 1px solid #ddd;
  word-wrap: break-word;         /* All browsers since IE 5.5+ */
  overflow-wrap: break-word;     /* Renamed property in CSS3 draft spec */
}
